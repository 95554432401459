import { Link, Outlet } from "react-router-dom";
import profil from "../assets/profile.gif"
import logo from "../assets/logo.png"
import { IoMdHome } from "react-icons/io";
import { MdEvent } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa";
import { GiHandOk } from "react-icons/gi";
import { useEffect, useState } from "react";


const SideBar = () => {
  const [selectedOption, setSelected] = useState(parseInt(localStorage.getItem("selectedOption")) || 1);
  const links = [
    {
      id: 1,
      name: "Dashboard",
      icone: IoMdHome,
      link: "/dashboard",
     
    },
    {
      id: 2,
      name: "Evènements",
      icone:  MdEvent,
      link: "/dashboard/events",
      
    },
    {
      id: 3,
      name: "Membres",
      icone: FaUserFriends,
      link: "/dashboard/membres",
     
    },
    {
      id: 4,
      name: "Partenaires",
      icone: FaHandshake,
      link: "/dashboard/partenaires",
      
    },
    {
      id: 5,
      name: "Témoignages",
      icone: GiHandOk,
      link: "/dashboard/temoignages",
      
    }
  ]

  useEffect(()=>{
    const savedSelectedOption = localStorage.getItem("selectedOption");
  
    if (savedSelectedOption) {
      setSelected(parseInt(savedSelectedOption));
    
    }
  }, [])

  const handleClick = (id)=>{
    setSelected(id);
    localStorage.setItem("selectedOption", id);

  }
    return ( 
        <div className="bg-slate-100 h-screen w-full flex">
            <div className="w-80 bg-white h-full">
                <div className="flex flex-col py-6 border-b-2">
                    <div className=" w-32 h-32  m-auto">
                      <div className="w-full h-full border-2 border-blue rounded-full">
                        <img src={profil} alt="" className=" w-full h-full object-cover" />
                        
                      </div>
                    </div>
                    <span className=" text-center font-bold">Admin</span>
                </div>

                <div className=" pb-4 border-b-2 ">
                  <ul className="flex flex-col items-start space-y-1  ">
                     {
                      links.map((link) => {
                        return (
                          <li key={link.id} className={`p-2 w-full  hover:bg-slate-100 ${link.id === selectedOption ? "bg-slate-100" : "bg-none"}`} onClick={() => handleClick(link.id)}>
                             <Link to={link.link} className="flex items-center">
                                  <link.icone className="w-8 h-8 pr-3 " />
                                  {link.name}
                             </Link>
                          </li>
                        )
                      })
                     }
                  </ul>
                </div>
                <div className=" pt-5">
                <button className="w-full p-3 text-center  bg-primary  text-white hover:shadow-md transition-all font-milky mt-3 block">
                    Se Deconnecter
                </button>
                </div>
                <div className="flex flex-col py-6">
                    <div className=" w-32 h-32 m-auto absolute bottom-0 left-12">
                        <img src={logo} alt="" className=" w-full object-cover" />
                    </div>
                </div>
            </div>
            <div className="w-full">
                <Outlet/>
            </div>

        </div>
     );
}
 
export default SideBar;
