import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaRegCalendarAlt, FaLink, FaImage, FaEdit, FaUser } from 'react-icons/fa';
import {FiType} from 'react-icons/fi';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import FormData from 'form-data';

const AddEvent = () => {
  const axiosPrivate = useAxiosPrivate();
  const [post, setPost] = useState({
    nom: '',
    type_event: '',
    description: '',
    email_intervenant: '',
    date_event: '',
    date_fin_inscription: '',
    lieu_event: '',
    lien_event: ''
  });
  const [image_event, setImage_event] = useState(null)

  const handleInput = (event) => {
    const { id, value } = event.target;
    setPost({ ...post, [id]: value });
  };

  const handleFileInput = (event) => {
    console.log(event.target.files[0])
    setImage_event(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    for (const key in post) {
      formData.append(key, post[key]);
    }
    const image = image_event;
    console.log(new FormData())

    if (image_event) {
      formData.append("image_event", image_event);
    } else {
      alert("Veuillez sélectionner une image.");
      return; // Stoppe la soumission si pas d'image
    }

    await axiosPrivate.post('/api/events', formData)
      .then((response) => {
        console.log(response);
        alert('Événement créé avec succès!');
      })
      .catch((error) => {
        console.error(error);
        alert('Erreur lors de la création de l\'événement!');
      });
  };

  return (
    <div className="relative p-5">
        <div className="flex justify-between items-center">
          <p className="text-xl text-blue">/Evènements/AjouterÉvènement</p>
        </div>
      <div className="bg-white p-9 mt-5 rounded-lg shadow-lg relative w-full max-w-3xl max-h-5xl text-center">
        <form onSubmit={handleSubmit}>
          <div className="flex justify-center mb-14">
            <h2 className="text-2xl text-blue font-bold text-center">Créer un Événement</h2>
          </div>

          <div className="flex flex-col space-y-4">
            <div className="flex items-center">
              <label className="block text-sm font-medium text-gray-700 w-1/3 text-left" htmlFor="name">
                Nom de l'Événement
              </label>
              <div className="flex items-center border border-gray-300 rounded-md flex-1">
                <FaEdit className="ml-2" />
                <input
                  type="text"
                  id="nom"
                  className="flex-1 p-2 border-0 focus:ring-0 focus:outline-none"
                  onChange={handleInput}
                />
              </div>
            </div>

            <div className="flex items-center">
              <label className="block text-sm font-medium text-gray-700 w-1/3 text-left" htmlFor="type">
                Type d'Événement
              </label>
              <div className="flex items-center border border-gray-300 rounded-md flex-1">
                <FiType className="ml-2" />
                <input
                  type="text"
                  id="type_event"
                  className="flex-1 p-2 border-0 focus:ring-0 focus:outline-none"
                  onChange={handleInput}
                />
              </div>
            </div>

            <div className="flex items-center">
              <label className="block text-sm font-medium text-gray-700 w-1/3 text-left" htmlFor="speaker">
                Intervenant dans l'événement
              </label>
              <div className="flex items-center border border-gray-300 rounded-md flex-1">
                <FaUser className="ml-2" />
                <input
                  type="text"
                  id="email_intervenant"
                  className="flex-1 p-2 border-0 focus:ring-0 focus:outline-none"
                  placeholder="Entrez l'email de l'intervenant"
                  onChange={handleInput}
                />
              </div>
            </div>

            <div className="flex items-center">
              <label className="block text-sm font-medium text-gray-700 w-1/3 text-left" htmlFor="description">
                Description de l'événement
              </label>
              <textarea
                id="description"
                className="flex-1 border border-gray-300 rounded-md p-2 focus:ring focus:outline-none"
                onChange={handleInput}
              ></textarea>
            </div>

            <div className="flex items-center">
              <label className="block text-sm font-medium text-gray-700 w-1/3 text-left" htmlFor="start-date-time">
                Date et Heure de Début de l'événement
              </label>
              <div className="flex items-center border border-gray-300 rounded-md flex-1">
                <FaRegCalendarAlt className="ml-2" />
                <input
                  type="datetime-local"
                  id="date_event"
                  className="flex-1 p-2 border-0 focus:ring-0 focus:outline-none"
                  onChange={handleInput}
                />
              </div>
            </div>

            <div className="flex items-center">
              <label className="block text-sm font-medium text-gray-700 w-1/3 text-left" htmlFor="end-date-time">
                Date et Heure de Fin de l'inscription
              </label>
              <div className="flex items-center border border-gray-300 rounded-md flex-1">
                <FaRegCalendarAlt className="ml-2" />
                <input
                  type="datetime-local"
                  id="date_fin_inscription"
                  className="flex-1 p-2 border-0 focus:ring-0 focus:outline-none"
                  onChange={handleInput}
                />
              </div>
            </div>

            <div className="flex items-center">
              <label className="block text-sm font-medium text-gray-700 w-1/3 text-left" htmlFor="location">
                Lieu de l'événement
              </label>
              <div className="flex items-center border border-gray-300 rounded-md flex-1">
                <input
                  type="text"
                  id="lieu_event"
                  className="flex-1 p-2 border-0 focus:ring-0 focus:outline-none"
                  onChange={handleInput}
                />
              </div>
            </div>

            <div className="flex items-center">
              <label className="block text-sm font-medium text-gray-700 w-1/3 text-left" htmlFor="link">
                Lien de l'événement (facultatif)
              </label>
              <div className="flex items-center border border-gray-300 rounded-md flex-1">
                <FaLink className="ml-2" />
                <input
                  type="url"
                  id="lien_event"
                  className="flex-1 p-2 border-0 focus:ring-0 focus:outline-none"
                  onChange={handleInput}
                />
              </div>
            </div>

            <div className="flex items-center">
              <label className="block text-sm font-medium text-gray-700 w-1/3 text-left" htmlFor="image">
                Image de l'événement
              </label>
              <div className="flex items-center border border-gray-300 rounded-md flex-1">
                <FaImage className="ml-2" />
                <input
                  type="file"
                  id="image_event"
                  className="flex-1 p-2 border-0 focus:ring-0 focus:outline-none"
                  onChange={handleFileInput}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between mt-10">
          <Link to="/dashbord/Events">
              <button
                type="button"
                className="bg-amber-500 text-white font-bold p-2 rounded hover:bg-amber-200 focus:outline-none focus:ring-2 focus:ring-orange-700 active:ring-2 active:ring-white transition duration-200"
              >
                Annuler
              </button>
            </Link>

            <button
              type="submit"
              className="bg-blue text-white font-bold p-2 rounded hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-white active:ring-2 active:ring-white transition duration-200"
            >
              Soumettre
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEvent;
