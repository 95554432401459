import React, { useRef, useState, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import ParticlesComponent from "./Particles";
import useAuth from '../../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../../api/axios';
const LOGIN_URL = '/login';


export default function Login() {

  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  //const userRef = useRef();
  //const errRef = useRef();

  const [error, setError] = useState('');
  const [isError, setIsError] = useState(false);
  const [isWhite, setIsWhite] = useState(true);
  const [isText, setIsText] = useState(false);
  const [isMail, setIsMail] = useState(false);
  const [mailvide, setMailVide] = useState(true);
  const [isPasse, setIsPasse] = useState(false);
  const [passeVide, setPasseVide] = useState(true);
  const [onProgress, setOnProgress] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    motDePasse: "",
  });

  const showPasse = () => {
    setIsText(!isText);
  }

  const followMail = (e) => {
    let isValide = true;
    let chanMail = document.getElementById("email");
    if (chanMail.value === "") {
      setMailVide(true);
      isValide = false;
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(chanMail.value)) {
      setMailVide(false);
      setIsMail(false);
      isValide = false;
    }
    else {
      setMailVide(false);
      setIsMail(true);
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    return isValide;
  }

  const followPasse = (e) => {
    let isValide = true;
    let chanPasse = document.getElementById("motDePasse");
    if (chanPasse.value === "") {
      setPasseVide(true);
      isValide = false;
    } else if (!/^.{8,}$/.test(chanPasse.value)) {
      setPasseVide(false);
      setIsPasse(false);
      isValide = false;
    }
    else {
      setPasseVide(false);
      setIsPasse(true);
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    return isValide;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try{
      setOnProgress(true);
      // les données récupérées du formulaire
      const formdata = new FormData();
      formdata.append("email", formData.email);
      formdata.append("motDePasse", formData.motDePasse);
      const data = {
        email: formData.email,
        motDePasse: formData.motDePasse
      };

      const response = await axios.post(LOGIN_URL,
        formdata,
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    );
    console.log(JSON.stringify(response?.data));
    console.log(JSON.stringify(response));
    const accessToken = response?.data?.accessToken;
    const user = response.data.user.email;
    const role = response?.data?.role;
    setAuth({
      user,
      roles: [role],
      accessToken
    });
    setFormData({email:"", motDePasse:""})
    navigate(from, { replace: true });

    }catch (error) {
      error  ? setError(true) : setError(false);
      console.log(error);
      if (!error?.response) {
        //setErrMsg('No Server Response');
      } else if (error.response?.status === 400) {
        //setErrMsg('Missing Username or Password');
      } else if (error.response?.status === 401) {
        //setErrMsg('Unauthorized');
      } else {
        //setErrMsg('Login Failed');
      }
      //errRef.current.focus();
    }
  };

  const handleError = () => {
    setIsError(false);
    setIsWhite(false);
  }
  return (
    <>
      <ParticlesComponent />
      <div className="flex flex-row min-h-[100vh] items-center justify-center ">

        <div className="w-max h-max bg-white rounded-md">
          <form onSubmit={handleSubmit} className="text-left flex flex-col space-y-2 max-sm:w-[100%] sm:w-[400px] mx-auto p-5 shadow-lg  rounded-md px-10 ">
            <img src="/logo.png" alt="Logo WeP" className="w-[150px] mx-auto mb-[-10px]" /> <br />
            <h1 className="text-center font-bold max-sm:text-[1.2rem] sm:text-4xl mb-[25px]">Connexion</h1>

            <label htmlFor="email" className="font-bold">Email</label>
            <input
              type="text"
              name="email"
              id="email"
              onInput={followMail}
              className="border border-c2 rounded-md outline-none px-3 py-2"
            />
            {
              isMail && (
                <div className="flex flex-row items-center text-blue-600">
                  <span className="pr-2 font-bold">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                  </span>
                  <span className="text-[0.7em]">Email valide</span>
                </div>
              )}

            {!isMail && !mailvide && (
              <div className="flex flex-row items-center text-red-600">
                <span className="pr-2 font-bold">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                  </svg>
                </span>
                <span className="text-[0.7em]">Email invalide</span>
              </div>
            )}
            <br />

            <label htmlFor="motDePasse" className="font-bold">Mot de Passe</label>
            <div className='grid grid-cols-[auto_50px]'>
              <input
                type={isText ? "text" : "password"}
                name="motDePasse"
                id="motDePasse"
                onInput={followPasse}
                className="border-b border-l border-t border-c2 rounded-l-md outline-none px-3 py-2 w-[100%]"
              />
              <span onClick={showPasse} className='h-[100%] bg-gray-300 border-t border-b border-r cursor-pointer border-c2 rounded-r-md flex items-center justify-center text-blue-700'>
                {
                  isText && (<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                  </svg>)

                }
                {
                  !isText && (<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                    <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                  </svg>)
                }


              </span>
            </div>
            {isPasse && (
              <div className="flex flex-row items-center text-blue-600">
                <span className="pr-2 font-bold">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                </span>
                <span className="text-[0.7em]">Au moins 8 caractères</span>
              </div>
            )}

            {!isPasse && !passeVide && (
              <div className="flex flex-row items-center text-red-600">
                <span className="pr-2 font-bold">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                  </svg>
                </span>
                <span className="text-[0.7em]">Au moins 8 caractères</span>
              </div>
            )}
            <br />

            <div className="flex max-sm:flex-col items-center justify-between flex-wrap">
              <button
                disabled={!isMail || !isPasse}
                type="submit"
                className={`px-4 py-2 bg-blue font-semibold rounded-md shadow-sm max-sm:w-full max-sm:mb-5 text-white ${(!isMail || !isPasse) ? 'opacity-75' : 'hover:font-bold hover:px-2'
                  }`}
              >
                Se connecter
              </button>
              <a
                href="/"
                className="text-sm hover:underline max-sm:w-full text-center my-3 font-bold"
              >
                Mot de passe oublié ?
              </a>
            </div>
          </form>
        </div >
      </div >
      {
        isError && (
          <div id="blockError" onClick={handleError} className='fixed top-0 left-0 right-0 bottom-0 bg-[#00000068] w-[100%] h-[100vh] flex flex-col items-center justify-center cursor-pointer'>
          </div>
        )
      }
      {
        isError && isWhite && (
          <div id="contenu" className='bg-white px-8 py-5 rounded-md slide-down min-h-[100px] max-w-[350px] mt-[-60vh] float-end mr-[38%] text-center text-c3 font-bold font-poppins'>
            {error}
          </div>
        )
      }
      {
        onProgress && (
          <div id="blockError" onClick={handleError} className='fixed top-0 left-0 right-0 bottom-0 bg-[#00000068] w-[100%] h-[100vh] flex flex-col items-center justify-center'>
            <ThreeDots color='#FFCB05' />
          </div>
        )
      }
    </>
  );
}
