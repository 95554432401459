

const Partenaires = () => {
    return ( 
        <div className="w-full p-5">
           <p className=" text-slate-600 text-xl">/ Partenaires</p>
        </div>
     );
}
 
export default Partenaires;