const Rapport = ({couleur, nom, value, icone}) => {
    return ( 
        <div className={`w-60 min-h-24 flex flex-col bg-white p-3  rounded-lg`}>
            <div className="flex justify-between gap-3">
              <p className={` ${couleur} font-[600]`}>{nom}</p>
              <span className={`w-10 h-10 rounded-full ${couleur} p-2 text-white text-5xl flex justify-center items-center`}>{icone}</span>
              
            </div>
            <div>
              <p className="text-2xl text-center">{value}</p>
            </div>

        </div>
     );
}
 
export default Rapport;