import { SiMicrosoftexcel } from "react-icons/si";
import { RiDeleteBin6Line } from "react-icons/ri";
const Membres = () => {
    return ( 
        <div className="w-full p-5">
           <p className=" text-xl text-orange-500">/ Membres</p>
          
            <div className=" py-5 flex gap-2 items-center justify-between">
                    <div className=" flex gap-2 items-center">
                    <div className="">
                            <span className="border border-r-0 p-1 bg-slate-500 text-white  border-slate-500 cursor-pointer rounded-l-full">Tous</span>
                            <span className="border border-r-0 p-1 border-slate-500 cursor-pointer">IT</span>
                            <span className="border border-r-0 p-1 border-slate-500 cursor-pointer ">Projet</span>
                            <span className="border  p-1 border-slate-500 cursor-pointer ">Design</span>
                            <span className="border border-l-0 p-1 border-slate-500 cursor-pointer rounded-r-full">Com</span>
                        </div>
                        <input
                        type="search"
                       
                        placeholder="Rechercher..."
                        className="border p-2 rounded-md mx-2 outline-none"
                        />
                    </div> 
                    <div>
                 <button className=" hover:shadow-md bg-red-500 rounded-md text-white p-2 text-center flex gap-2 items-center">Exporter <SiMicrosoftexcel  className="text-xl"/></button>
               </div>
                        
            </div>

            <div>
            <table className="w-full border border-collapse border-slate-500">
        <thead>
          <tr className="bg-slate-500 text-white">
            <th className="py-2 px-4 text-left">Type</th>
            <th className="py-2 px-4 text-left">Nom</th>
            <th className="py-2 px-4 text-left">Prenom</th>
            <th className="py-2 px-4 text-left">Contact</th>
            <th className="py-2 px-4 text-left">Département</th>
            <th className="py-2 px-4 text-center">Compétences</th>
            <th className="py-2 px-4 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          
            <tr
             
              className={`border-b border-slate-500`}
            >
              <td
                className={`py-2 px-4 text-left  text-red-500 font-bold`}
              >
                Leader
              </td>
              <td
                className={`py-2 px-4 text-left `}
              >
               SOSSOUKPE
              </td>
              <td className="py-2 px-4 text-left">
                Fènou Amos
              </td>
              <td className="py-2 px-4 text-left">+229 52109652</td>
              <td className="py-2 px-4 text-center text-red-500 font-bold">IT</td>
              <td className="py-2 px-4 text-center">[développement, leadership, graphisme]</td>
              <td className="py-2 px-4 text-center"> 
                 <button className="bg-red-500 p-2 transition-all">
                    <RiDeleteBin6Line className=" text-white hover:font-bold" />
                  </button>
              </td>
              
            </tr>
            
            <tr
             
             className={`border-b border-slate-500`}
           >
             <td
               className={`py-2 px-4 text-left  text-red-500 font-bold`}
             >
               CoLeader
             </td>
             <td
               className={`py-2 px-4 text-left `}
             >
              SOSSOUKPE
             </td>
             <td className="py-2 px-4 text-left">
               Fènou Amos
             </td>
             <td className="py-2 px-4 text-left">+229 52109652</td>
             <td className="py-2 px-4 text-center text-green-500 font-bold">Projet</td>
             <td className="py-2 px-4 text-center">[gestion de projet, leadership, redaction web]</td>
             <td className="py-2 px-4 text-center"> 
                 <button className="bg-red-500 p-2 transition-all">
                    <RiDeleteBin6Line className=" text-white hover:font-bold" />
                  </button>
              </td>
           </tr>
           <tr
             
             className={`border-b border-slate-500`}
           >
             <td
               className={`py-2 px-4 text-left  text-red-500 font-bold`}
             >
               Simple
             </td>
             <td
               className={`py-2 px-4 text-left `}
             >
              BABA 
             </td>
             <td className="py-2 px-4 text-left">
               Paul
             </td>
             <td className="py-2 px-4 text-left">+229 52109652</td>
             <td className="py-2 px-4 text-center text-orange-500 font-bold">Com</td>
             <td className="py-2 px-4 text-center">[développement, graphisme, redaction web]</td>
             <td className="py-2 px-4 text-center"> 
                 <button className="bg-red-500 p-2 transition-all">
                    <RiDeleteBin6Line className=" text-white hover:font-bold" />
                  </button>
              </td>
           </tr>
           <tr
             
             className={`border-b border-slate-500`}
           >
             <td
               className={`py-2 px-4 text-left  text-red-500 font-bold`}
             >
               Leader
             </td>
             <td
               className={`py-2 px-4 text-left `}
             >
              TETEDE
             </td>
             <td className="py-2 px-4 text-left">
               Diane
             </td>
             <td className="py-2 px-4 text-left">+229 52109652</td>
             <td className="py-2 px-4 text-center text-orange-500 font-bold">Com</td>
             <td className="py-2 px-4 text-center">[développement, graphisme, redaction web]</td>
             <td className="py-2 px-4 text-center"> 
                 <button className="bg-red-500 p-2 transition-all">
                    <RiDeleteBin6Line className=" text-white hover:font-bold" />
                  </button>
              </td>
           </tr>
      
        </tbody>
      </table>
            </div>
        </div>
     );
}
 
export default Membres;