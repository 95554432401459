import React from "react";
import { Link } from 'react-router-dom';
import { CgAdd } from "react-icons/cg";
import TemplateEvent from "../../../components/TemplateEvent";

const Events = () => {
    //const location = useLocation();

    //const isAddEventRoute = location.pathname === "/dashboard/Events/AddEvent";

    return (
        <div className="relative">
            <div className="w-full p-5">
                <div className="flex justify-between items-center">
                    <p className="text-xl text-blue">/Evènements</p>
                    <Link to="/dashboard/Events/AddEvent">
                        <button
                            className="hover:shadow-md bg-blue rounded-md text-white p-2 text-center flex gap-2 items-center"
                        >
                            Ajouter <CgAdd className="text-xl" />
                        </button>
                    </Link>
                </div>

                <div className="mt-4">
                    <div>
                        <span className="border border-r-0 p-1 bg-blue text-white border-primary cursor-pointer rounded-l-full">Tous</span>
                        <span className="border p-1 border-primary cursor-pointer">Webinaires</span>
                        <span className="border border-l-0 p-1 border-primary cursor-pointer rounded-r-full">Formations</span>
                    </div>
                </div>

                <div className="flex gap-3 flex-wrap py-5">
                    <TemplateEvent />
                    <TemplateEvent />
                    <TemplateEvent />
                </div>
            </div>

            
        </div>
    );
};

export default Events;
