
import Rapport from "../../../components/Rapport";
import welcomeAnim from "../../../assets/welcomeAnim.gif"
import { IoEyeSharp } from "react-icons/io5";
import { MdEvent } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa";
const Home = () => {

  const rapports = [
    {
      id:1,
      nom:"Nbre de visiteurs",
      value:10,
      icone:<IoEyeSharp/>,
      couleur:"text-emerald-500"
    },
    {
      id:2,
      nom:"Nbre de Membres",
      value:1000,
      icone:<FaUserFriends/>,
      couleur:"text-orange-500"
    },
    {
      id:3,
      nom:"Nbre d'Evènements",
      value:25,
      icone:<MdEvent/>,
      couleur:"text-red-500"
    },{
      id:4,
      nom:"Nbre de Partenaires",
      value:2,
      icone:<FaHandshake />,
      couleur:"text-slate-600"
    },
  ]
    return ( <div className="w-full h-full bg-slate-100 pt-3">
              <div className="w-[90%] mx-auto h-60 bg-white rounded-xl flex justify-between items-center px-8">
                <div className=" max-w-[500px]">
                  <p className=" text-3xl">Bienvenue sur le <span className="text-blue">Tableau de bord</span> de <span className="bg-gradient-to-r  from-red-600 to-orange-500 inline-block text-transparent bg-clip-text">DataAfrique Hub</span></p>
                </div>
                <div>
                  <img src={welcomeAnim} alt="" className=" "/>
                </div>
            
              </div>
              <div className=" w-[90%] mx-auto py-3">
                <p className=" text-xl font-bold">Les statistiques</p>

              </div>
              <div className=" w-[90%] mx-auto flex flex-wrap gap-4">

                 {
                  rapports.map((rapport) => (
                    <Rapport key={rapport.id} icone={rapport.icone} couleur={rapport.couleur} nom={rapport.nom} value={rapport.value}/>
                  ))
                 }

              </div>

            </div> );
}
 
export default Home;