import affiche1 from "../assets/events/affiche1.png"

const TemplateEvent = () => {
    return ( 
       
        <div className="border border-slate-400 bg-white relative w-64 min-h-[380px] ">
            <img src={affiche1} alt="" className="w-full h-60 object-cover rounded-tl-xl" />
            <span className="absolute top-0 right-0 bg-primary p-2 text-white">A Venir </span>
            <p className="p-1">Date : <span>18/12/2024</span></p>
            <p className="p-1">Thème :  <span>Data science</span></p>
            <p className="p-1">Categorie :  <span>Webinaire</span></p>
            <div className=" p-2 space-y-2">
                <button className="bg-blue hover:shadow-md rounded-full w-full text-center text-white p-1">Voir plus</button>
                <button className="bg-amber-500 hover:shadow-md rounded-full w-full text-center text-white p-1">Liste des participants</button>
            </div>
           
        </div>
    );
}
 
export default TemplateEvent;