

const Temoignages = () => {
    return ( 
        <div className="w-full p-5">
           <p className=" text-xl text-red-500">/ Temoignages</p>
        </div>
     );
}
 
export default Temoignages;